<template>
  <div>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="headline primary white--text">
        Create Customer
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="customerForm" v-model="formValid">
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="px-1">
              <label class="font-weight-medium" for="name">
                First Name <span class="red--text">*</span></label
              >
              <v-text-field
                @input="form.name = $event.toUpperCase()"
                v-model.trim="form.name"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="px-1">
              <label class="font-weight-medium" for="lastname">
                Last Name <span class="red--text">*</span></label
              >
              <v-text-field
                @input="form.lastName = $event.toUpperCase()"
                v-model.trim="form.lastName"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="dob">
                DOB <span class="red--text">*</span></label
              >
              <DobPicker @dateDOB="form.birthDate = $event" />
              <!--  <ma-date-picker
                :rules="[rules.required]"
                v-model="form.birthDate"
                past
                :editable="true"
              /> -->
            </v-col>
            <v-col cols="12" sm="2" class="px-1">
              <label class="font-weight-medium" for="gender"
                >Gender <span class="red--text">*</span>
              </label>
              <v-select
                :items="genders"
                item-text="label"
                item-value="value"
                v-model="form.isMale"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="phone">
                Phone <span class="red--text">*</span></label
              >
              <v-text-field
                v-model="form.phone"
                :rules="[rules.phone]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="email"> Email </label>
              <v-text-field
                v-model="form.email"
                :rules="[rules.email]"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="address">Address </label>
              <v-text-field
                @input="form.address.addressLine1 = $event.toUpperCase()"
                v-model="form.address.addressLine1"
                prepend-inner-icon="mdi-map-marker"
                dense
                autocomplete="street-address"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="2" class="pl-1">
              <label class="font-weight-medium" for="apto">Apto </label>
              <v-text-field
                @input="form.address.addressLine2 = $event.toUpperCase()"
                v-model="form.address.addressLine2"
                dense
                autocomplete="apto-address"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="2" class="px-1">
              <label class="font-weight-medium" for="state">State </label>
              <v-autocomplete
                v-model="state"
                :items="states"
                item-text="name"
                item-value="id"
                :loading="loadingStates"
                autocomplete="off"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="2" class="pr-1">
              <label class="font-weight-medium" for="city">City </label>
              <v-autocomplete
                :disabled="stateCities.length == 0"
                v-model.number="form.address.city"
                :items="stateCities"
                item-text="name"
                item-value="id"
                :loading="loadingCities"
                autocomplete="off"
                mandatory
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="2">
              <label class="font-weight-medium" for="zip">Zip </label>
              <v-text-field
                v-model="form.address.zipCode"
                :rules="[rules.zip]"
                autocomplete="postal-code"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="other_phones"
                >Others Phone
                <template v-if="existnum"
                  ><span class="red--text">{{
                    "(That phone already exists)"
                  }}</span></template
                >
              </label>
              <v-text-field
                v-model="otherPhone"
                outlined
                dense
                :rules="[rules.phone1]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <div class="d-flex align-center fill-height">
                <v-btn
                  color="success"
                  text
                  class="mb-2"
                  :disabled="!validOther || existnum"
                  @click="addOtherPhone"
                >
                  <v-icon>mdi-plus</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" sm="7">
              <template v-if="otherPhones.length != 0">
                <label class="font-weight-medium" for="other_phones"
                  >Others Phones:
                </label>
                <div>
                  <v-chip
                    class="ma-2"
                    v-for="(item, index) in otherPhones"
                    :key="index"
                  >
                    {{ item }}
                  </v-chip>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-form>

        <v-alert type="error" :value="formValid == false">
          {{
            !validDate
              ? "Invalid Date of Birth"
              : "All fields with ( * ) are required"
          }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="error"
          :disabled="loading"
          width="100"
          dark
          depressed
          rounded
          @click="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          class="ml-3"
          color="primary"
          :disabled="loading || validAddForm == false"
          :loading="loading"
          width="100"
          depressed
          rounded
          @click="create"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import rules from "@/components/account/rules";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import DobPicker from "@/components/MaDatePicker/DobPicker.vue";
import { notifyError, notifySuccess } from "@/components/Notification";
import cleanObject from "@/utils/cleanObject";
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  components: { DobPicker },
  props: {
    inDialog: Boolean,
  },
  data() {
    return {
      loading: false,
      formValid: false,
      loadingCities: false,
      stateCities: [],
      state: 0,
      otherPhone: null,
      otherPhones: [],
      form: {
        name: null,
        lastName: null,
        birthDate: null,
        email: null,
        phone: null,
        isMale: true,
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          zipCode: null,
        },
      },
      rules: {
        required: rules.required,
        email: rules.emailnull,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    validAddForm() {
      return this.validDate && this.formValid;
    },
    validDate() {
      if (this.form.birthDate == "" || this.form.birthDate == null) {
        return false;
      }
      const d = moment(this.form.birthDate);
      const now = moment();
      if (d.isBefore(now)) {
        return true;
      }
      return false;
    },
    validOther() {
      if (this.otherPhone == null || this.otherPhone == "") {
        return false;
      }
      return /^[1-9][0-9]{9}$/.test(this.otherPhone);
    },
    noDataAddress() {
      const add = this.form.address;
      if (
        add.addressLine1 == null &&
        add.addressLine2 == null &&
        add.state == null &&
        add.city == null &&
        add.zipCode == null
      ) {
        return true;
      }
      return false;
    },

    existnum() {
      if (this.form.phone && this.otherPhone == this.form.phone) {
        return true;
      }
      if (this.otherPhones.length == 0) {
        return false;
      }

      const x = this.otherPhones.find((op) => op == this.otherPhone);
      if (x) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.form.address.state = stateId;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),

    create() {
      let body = Object.assign({}, this.form);

      body.phone = "+1" + body.phone;
      body.birthDate = moment(body.birthDate).toISOString();
      if (this.noDataAddress) {
        delete body.address;
      } else {
        if (body.address.state) {
          body.address.state = body.address.state.toString();
        }
        if (body.address.city) {
          body.address.city = body.address.city.toString();
        }
      }
      body.name = body.name.trim();
      body.lastName = body.lastName.trim();
      body = cleanObject(body);

      if (this.otherPhones.length != 0) {
        const ophones = this.otherPhones.map((op) => {
          return "+1" + op;
        });
        body = { ...body, otherPhones: ophones };
      }

      this.loading = true;
      getAPI
        .post("/customer/create", body)
        .then((res) => {
          this.loading = false;
          const uuid = res.data.uuid;
          notifySuccess("Customer has been created");
          if (this.inDialog) {
            this.$emit("customerCreate",res.data);
          } else {
            this.$router.push(`/customers/details/${uuid}`);
          }
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancel() {
      if (this.inDialog) {
        this.$emit("closeDialog");
      } else {
        this.$router.push(`/customers`);
      }
    },

    addOtherPhone() {
      if (this.otherPhones.length == 0) {
        this.otherPhones.push(this.otherPhone);
        this.otherPhone = null;
      } else {
        const x = this.otherPhones.find((op) => op == this.otherPhone);
        if (x) {
          return;
        } else {
          this.otherPhones.push(this.otherPhone);
          this.otherPhone = null;
        }
      }
    },
  },
  async mounted() {
    await this.actListStates();
  },
};
</script>
<style lang=""></style>
